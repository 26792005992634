import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import Table from '../../../TableContainer/Table';
import { getColumnWidths } from '../../../TableContainer/TableUtilities/getColumnWidths';
import { getColumnCompareWithUndefined } from '../../../TableContainer/TableUtilities/columnFormatter';
import { getReportReports } from '../../../Reporting/requests';
import { getTableDataWithMostUpdated } from '../../../TableContainer/TableUtilities/buildTableRequest';
import {
  renderDefaultCell,
  renderCurrencyCell
} from '../../../TableContainer/TableUtilities/defaultCells';
import {
  handleGetDataChange,
  handleLastUpdatedChange
} from '../../../lenox/actions';
import { isMobileDevice } from '../../../common/isMobileDevice';
import CSVButton from '../../../TableContainer/TableUtilities/CSVButton';
import { logError } from '../../../../Utilities/logError';

const monthColumns = [
  {
    name: 'JanuaryTotal',
    title: 'January',
    width: 150,
    compare: 'priority',
    month: '01'
  },
  {
    name: 'FebruaryTotal',
    title: 'February',
    width: 150,
    compare: 'priority',
    month: '02'
  },
  {
    name: 'MarchTotal',
    title: 'March',
    width: 150,
    compare: 'priority',
    month: '03'
  },
  {
    name: 'AprilTotal',
    title: 'April',
    width: 150,
    compare: 'priority',
    month: '04'
  },
  {
    name: 'MayTotal',
    title: 'May',
    width: 150,
    compare: 'priority',
    month: '05'
  },
  {
    name: 'JuneTotal',
    title: 'June',
    width: 150,
    compare: 'priority',
    month: '06'
  },
  {
    name: 'JulyTotal',
    title: 'July',
    width: 150,
    compare: 'priority',
    month: '07'
  },
  {
    name: 'AugustTotal',
    title: 'August',
    width: 150,
    compare: 'priority',
    month: '08'
  },
  {
    name: 'SeptemberTotal',
    title: 'September',
    width: 150,
    compare: 'priority',
    month: '09'
  },
  {
    name: 'OctoberTotal',
    title: 'October',
    width: 150,
    compare: 'priority',
    month: '10'
  },
  {
    name: 'NovemberTotal',
    title: 'November',
    width: 150,
    compare: 'priority',
    month: '11'
  },
  {
    name: 'DecemberTotal',
    title: 'December',
    width: 150,
    compare: 'priority',
    month: '12'
  }
];

const buildTableParams = (action, props) => ({
  action,
  year: props.year
});

function BuyerSummary(props) {
  const [rows, setRows] = useState([]);
  const [loading, isLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);
  const requestTime = React.createRef();

  const getRequestTime = () => requestTime.current.toString();

  useEffect(() => {
    getData();
    props.handleGetDataChange(getData);

    return () => {
      props.handleLastUpdatedChange('');
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const columns = useMemo(() => {
    const filterMonthColumns = monthColumns.filter((column) => {
      return moment(`${props.year}-${column.month}-01`).isBefore();
    });

    return [
      { name: 'buyer', title: 'Buyer', width: 400 },
      ...filterMonthColumns,
      { name: 'total', title: 'Total', width: 150, compare: 'priority' }
    ];
  }, [props.year]);

  const getData = () => {
    const requestTimeNew = new Date().getTime() / 1000;

    requestTime.current = requestTimeNew;

    isLoading(true);
    setLoadingError(false);

    getTableDataWithMostUpdated(
      buildTableParams('getBuyerSummaryReport', props),
      getReportReports,
      (values) => {
        if (requestTimeNew.toString() === getRequestTime()) {
          const { rows, loading, lastUpdated } = values;

          setRows(rows);
          isLoading(loading);

          props.handleLastUpdatedChange(lastUpdated);
        }
      }
    ).catch((error) => {
      logError(error);
      setRows([]);
      isLoading(false);
      loadingError(true);
    });
  };

  const cellComponent = ({ row, column }) => {
    switch (column.name) {
      case 'total':
      case 'JanuaryTotal':
      case 'FebruaryTotal':
      case 'MarchTotal':
      case 'AprilTotal':
      case 'MayTotal':
      case 'JuneTotal':
      case 'JulyTotal':
      case 'AugustTotal':
      case 'SeptemberTotal':
      case 'OctoberTotal':
      case 'NovemberTotal':
      case 'DecemberTotal':
        return !row[column.name]
          ? renderCurrencyCell(0)
          : renderCurrencyCell(row[column.name]);
      default:
        return renderDefaultCell(row[column.name]);
    }
  };

  return (
    <>
      {!isMobileDevice() && (
        <CSVButton
          params={props}
          columns={columns}
          filename="Buyer Summary Report"
          data={rows}
          marginTop="1rem"
          showButton={Boolean(rows.length) && !loading}
        />
      )}
      <Table
        loading={loading}
        rows={rows}
        columns={columns}
        cellComponent={cellComponent}
        defaultColumnWidths={getColumnWidths(columns)}
        getColumnCompare={getColumnCompareWithUndefined(columns)}
        tableColumnLocalStorageName="buyer_summary_report"
        defaultSortingOrder={[{ columnName: 'total', direction: 'desc' }]}
        drawerTitle="Buyer Summary"
        loadingError={loadingError}
      />
    </>
  );
}

export default connect(
  (state) => ({
    year: state.filters.year
  }),
  (dispatch) =>
    bindActionCreators(
      {
        handleGetDataChange,
        handleLastUpdatedChange
      },
      dispatch
    )
)(BuyerSummary);

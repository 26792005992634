/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/static-property-placement */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Drawer, Box, Button, Tooltip } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { ChevronRight, Edit as EditIcon, Add } from '@mui/icons-material';
import { colors } from '../../Utilities/LenoxColors';
import { isMobileDevice } from '../common/isMobileDevice';

const styles = () => ({
  button: {
    fontSize: '.75rem',
    backgroundColor: colors.lenoxSuccess1,
    color: colors.lenoxLight1
  },
  mobileTableButton: {
    fontSize: '1.1rem',
    marginTop: '1rem',
    backgroundColor: colors.lenoxSuccess1,
    color: colors.lenoxLight1
  },
  drawer: {
    width: 800
  },
  bigDrawer: {
    width: 1028
  },
  drawerMobile: {
    width: '100vw'
  },
  drawerMobileWithTable: {
    width: '100vw',
    height: '100vh'
  },
  headerRow: {
    backgroundColor: colors.lenoxSuccess1,
    height: '5rem',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '2rem'
  },
  drawerButton: {
    color: 'white',
    marginLeft: '1rem'
  },
  drawerTitle: {
    width: '90%',
    textAlign: 'center',
    fontWeight: '100',
    fontSize: '1.35rem',
    marginLeft: '-50px',
    color: 'white'
  },
  disableRipple: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
});

class ActionDrawer extends Component {
  static propTypes = {
    drawerTitle: PropTypes.string.isRequired,
    content: PropTypes.any.isRequired,
    fullWidth: PropTypes.bool,
    editIconButton: PropTypes.bool,
    addIcon: PropTypes.bool,
    customIcon: PropTypes.any,
    textButton: PropTypes.bool,
    tooltip: PropTypes.string,
    disabled: PropTypes.bool,
    buttonTestId: PropTypes.string
  };

  static defaultProps = {
    fullWidth: false,
    editIconButton: false,
    addIcon: false,
    tooltip: '',
    disabled: false
  };

  state = {
    open: false
  };

  toggleDrawer = () => this.setState({ open: !this.state.open });

  formatButtonReturn = (title, classes, mobileButtonStyle) => {
    if (this.props.editIconButton) {
      return (
        <IconButton
          disabled={this.props.disabled}
          onClick={this.toggleDrawer}
          size="large"
          data-testid={this.props.buttonTestId}
        >
          <EditIcon />
        </IconButton>
      );
    }
    if (this.props.addIcon) {
      return (
        <IconButton
          onClick={this.toggleDrawer}
          size="large"
          data-testid={this.props.buttonTestId}
        >
          <Add />
        </IconButton>
      );
    }
    if (this.props.customIcon) {
      return (
        <IconButton
          disabled={this.props.disabled}
          onClick={this.toggleDrawer}
          size="large"
          data-testid={this.props.buttonTestId}
        >
          {this.props.customIcon}
        </IconButton>
      );
    }
    if (this.props.textButton) {
      return (
        <IconButton
          onClick={this.toggleDrawer}
          className={classes.disableRipple}
          disableFocusRipple
          disableRipple
          size="large"
          data-testid={this.props.buttonTestId}
        >
          {this.props.textButton}
        </IconButton>
      );
    }
    return (
      <Button
        variant="contained"
        className={
          mobileButtonStyle ? classes.mobileTableButton : classes.button
        }
        style={{ width: this.props.fullWidth ? '100%' : 'inherit' }}
        onClick={() => this.toggleDrawer()}
        disabled={this.props.disabled}
        data-testid={this.props.buttonTestId}
      >
        {title}
      </Button>
    );
  };

  renderButton = (title, classes, mobileButtonStyle) =>
    this.props.inline ? (
      <IconButton
        size="large"
        style={{ padding: 0 }}
        onClick={this.toggleDrawer}
        data-testid={this.props.buttonTestId}
      >
        <ChevronRight />
      </IconButton>
    ) : (
      this.formatButtonReturn(title, classes, mobileButtonStyle)
    );

  renderDrawer = (drawerTitle, content, classes, biggerDrawer) => {
    const { drawerIsOpen, onClose } = this.props;
    const { open } = this.state;

    const openState = drawerIsOpen ?? open;

    const onCloseDrawer = onClose ?? this.toggleDrawer;

    return (
      <Drawer
        anchor={isMobileDevice() ? 'left' : 'right'}
        open={openState}
        onClose={onCloseDrawer}
        // PaperProps={{
        //   sx: { width: window.innerWidth, height: window.innerHeight }
        // }}
        disabled
      >
        {this.renderDrawerContent(
          drawerTitle,
          content,
          classes,
          biggerDrawer,
          onCloseDrawer
        )}
      </Drawer>
    );
  };

  renderDrawerContent = (
    drawerTitle,
    content,
    classes,
    biggerDrawer,
    onCloseDrawer
  ) => {
    const regularClass = biggerDrawer ? classes.bigDrawer : classes.drawer;
    const actualClass = isMobileDevice()
      ? (this.props.drawerMobTable && classes.drawerMobileWithTable) ||
        classes.drawerMobile
      : regularClass;

    return (
      <Box className={actualClass}>
        <div className={classes.headerRow}>
          <IconButton
            className={classes.drawerButton}
            onClick={onCloseDrawer}
            size="large"
            data-testid="closeDrawerButton"
          >
            <ChevronRight />
          </IconButton>
          <div className={classes.drawerTitle}>{drawerTitle}</div>
        </div>
        {content}
      </Box>
    );
  };

  render() {
    const {
      drawerTitle,
      content,
      classes,
      fullWidth,
      tooltip = '',
      biggerDrawer = false,
      buttonTitle = false,
      inline = false,
      mobileButtonStyle = false,
      hideButton = false
    } = this.props;
    let drawerContent = content;
    if (typeof content === 'function' && content.length === 2) {
      drawerContent = content(this.toggleDrawer, this.props.updateParent);
    } else if (typeof content === 'function') {
      drawerContent = content(this.toggleDrawer);
    }
    return (
      <div
        style={{
          width: fullWidth ? '100%' : 'auto',
          display: inline ? 'inline-block' : 'block'
        }}
        title={drawerTitle}
      >
        {!hideButton && (
          <Tooltip title={tooltip || ''} placement="top">
            {this.renderButton(
              buttonTitle || drawerTitle,
              classes,
              mobileButtonStyle
            )}
          </Tooltip>
        )}
        {this.renderDrawer(drawerTitle, drawerContent, classes, biggerDrawer)}
      </div>
    );
  }
}

export default withStyles(ActionDrawer, styles);

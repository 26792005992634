/* eslint-disable import/no-cycle */
import { type FC, memo, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Paper } from '@mui/material';
import { handleChange, resetState } from './actions';
import { getFromLocalStorage } from '../Utilities/saveStateHelper';
import SideMenu from './SideMenu';
import Footer from '../Footer/Footer';
import { detectTablet } from '../common/utilities';
import { isMobileDevice } from '../common/isMobileDevice';
import { usePageContainerStyles } from './styles';
import { PageContent } from './PageContent';
import { type IPageListElementType } from '../../Utilities/types/pageContainerTypes';

export const localStoragePageName = 'selectedPage';
const localStorageMenuOpen = 'menuOpen';

interface IStoreState {
  pageReducer: {
    menuOpen: boolean;
    selectedPage: string;
    route: string;
  };
}

interface IPageContainerProps {
  defaultPage: string;
  pageList: Record<string, IPageListElementType>;
  sections: Array<unknown>;
  collapsable?: boolean;
  pageOrder?: Array<unknown>;
  showInnerFooter?: boolean;
  hideSideMenu?: boolean;
}

const PageContainer: FC<IPageContainerProps> = ({
  defaultPage,
  pageList,
  sections,
  collapsable,
  showInnerFooter,
  hideSideMenu = false,
  pageOrder = []
}) => {
  const { classes } = usePageContainerStyles();
  const randomKey = useRef(Math.floor(Math.random() * 1000));

  const { menuOpen, selectedPage } = useSelector((state: IStoreState) => {
    const { menuOpen, route, selectedPage } = state.pageReducer;

    return { menuOpen, route, selectedPage };
  });

  const dispatch = useDispatch();

  const handleAndDispatch = useCallback(
    (...handleChangeProps: Parameters<typeof handleChange>) => {
      return dispatch(handleChange(...handleChangeProps));
    },
    [dispatch]
  );

  useEffect(() => {
    const route = window.location.href.split('/').pop() || 'Affiliates';

    handleAndDispatch('route', route);

    const value = getFromLocalStorage(`${route}${localStoragePageName}`, {
      defaultPage
    });

    if (pageList[value]) {
      handleAndDispatch(localStoragePageName, value);
    } else {
      handleAndDispatch(localStoragePageName, defaultPage);
    }

    return () => {
      dispatch(resetState());
    };
  }, [defaultPage, dispatch, handleAndDispatch, pageList]);

  const toggleMenuVisibility = useCallback(() => {
    handleAndDispatch(localStorageMenuOpen, !menuOpen);
  }, [handleAndDispatch, menuOpen]);

  const isSideMenuOpen = menuOpen && selectedPage && !hideSideMenu;
  const isPageContentShown = !isMobileDevice() || detectTablet() || !menuOpen;

  const isActionItemsExist =
    !!pageList[selectedPage]?.actionItems &&
    (pageList[selectedPage]?.actionItems?.length ?? -1) > 0;

  if (!(selectedPage && pageList[selectedPage])) {
    return null;
  }

  return (
    <Paper id="pageContainer" key={randomKey.current}>
      <Grid
        container
        className={classes.container}
        justifyContent="center"
        alignItems="flex-start"
      >
        {isSideMenuOpen && (
          <SideMenu
            pageList={pageList}
            sections={sections}
            collapsable={!!collapsable}
            pageOrder={pageOrder}
          />
        )}
        {isPageContentShown && (
          <PageContent
            hideSideMenu={hideSideMenu}
            isSideMenuOpen={!hideSideMenu && menuOpen}
            isActionItemsExist={isActionItemsExist}
            selectedPageListItem={pageList[selectedPage]}
            toggleMenuVisibility={toggleMenuVisibility}
            showInnerFooter={!!showInnerFooter}
          />
        )}
      </Grid>
      {!showInnerFooter && <Footer />}
    </Paper>
  );
};

export default memo(PageContainer);

/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-return-await */
/* eslint-disable react/no-will-update-set-state */
/* eslint-disable react/static-property-placement */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typography, Paper, TextField, MenuItem } from '@mui/material';
import { find, isEqual } from 'lodash';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { withStyles } from 'tss-react/mui';
import OptionList from './OptionsList';
import {
  checkAccounting,
  checkAdmin
} from '../../../PermissionsWrappers/permissionChecks';
import { formatAffiliateValue } from '../../../Utilities/getAffiliateIDFromName';

const styles = (theme) => ({
  stateContainer: {
    width: '100%!important',
    right: 0,
    display: 'inline-block',
    position: 'relative'
  },
  optionsContainerOpen: {
    position: 'absolute',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    left: 0,
    right: 0,
    width: '100%!important',
    backgroundColor: 'white',
    zIndex: '1000',
    height: '10rem',
    overflow: 'scroll'
  },
  suggestion: {
    display: 'block',
    width: '100%!important'
  },
  optionsList: {
    margin: 0,
    padding: 0,
    width: '100%!important',
    listStyleType: 'none'
  }
});

class AutoComplete extends Component {
  static propTypes = {
    value: PropTypes.string,
    message: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  };

  state = {
    value: '',
    options: [],
    filteredOptions: [],
    internalUpdate: false
  };

  componentDidMount() {
    this.getOptions(this.props);
    this.setState({ value: this.props.value });
    this.handleBlur();
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (this.props.dependency !== nextProps.dependency) {
      this.getOptions(nextProps);
    }
    if (this.state.value !== nextProps.value && !nextState.internalUpdate) {
      this.handleChange(nextProps.value);
    }
    if (
      nextState.internalUpdate === true &&
      this.state.value === nextState.value
    ) {
      this.setState({ internalUpdate: false });
    }
    if (
      typeof this.props.options !== 'function' &&
      !isEqual(nextProps.options, this.props.options)
    ) {
      this.getOptions(nextProps);
    }
  }

  getOptions = async (props) => {
    if (typeof props.options !== 'function') {
      if (typeof props.options.then === 'function') {
        return await props.options.then((options) => {
          this.setAffiliateDefaults(options);
          this.setState({ options: [...options] });
        });
      }
      this.setState({ options: [...props.options] });
      return;
    }
    if (typeof props.options().then === 'function') {
      return await props.options(props.dependency || false).then((options) => {
        this.setAffiliateDefaults(options);
        this.setState({ options: [...options] });
      });
    }
    this.setState({ options: [...props.options()] });
  };

  setAffiliateDefaults = (options) => {
    if (
      this.props.name === 'affiliateID' &&
      !checkAdmin() &&
      !checkAccounting()
    ) {
      if (
        options &&
        options.length === 2 &&
        typeof this.props.hideFilter === 'function' &&
        (options[0].value || '').includes('all')
      ) {
        this.props.hideFilter(this.props.name, true);
        this.props.onChange(this.props.name, options[1].display);
      } else if (typeof this.props.hideFilter === 'function') {
        this.props.hideFilter(this.props.name, false);
      }
    }
  };

  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    return inputValue.length
      ? this.state.options.filter(({ display: option }) =>
          option.toLowerCase().includes(inputValue)
        )
      : [];
  };

  handleSuggestionsClearRequested = () => {
    this.setState({ filteredOptions: this.state.options });
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      filteredOptions: this.getSuggestions(value)
    });
  };

  submitChange = (value, selectedOption = null) => {
    this.props.onChange(this.props.name, value, selectedOption);

    if (this.props.name === 'affiliateID' && this.props.checkForGiraffe) {
      const affOption = find(this.state.options, [
        'value',
        formatAffiliateValue(value)
      ]);
      this.props.onChange('isGiraffeAffiliate', affOption.isGiraffe);
      localStorage.setItem('isGiraffeAffiliate', affOption.isGiraffe);
    }
  };

  handleChange = (value) => {
    this.setState({ value, internalUpdate: true });

    if (this.props.allowChange) {
      return this.submitChange(value);
    }

    const selectedOption = this.state.options?.find((option) => {
      return option.display === value;
    });

    if (!selectedOption) {
      return;
    }

    this.submitChange(value, selectedOption);
  };

  handleBlur = () => {
    const affiliateInput = document.querySelector('#affiliateID');
    if (!affiliateInput) {
      return false;
    }
    affiliateInput.addEventListener('blur', (e) => {
      setTimeout(() => {
        let shouldRevert = true;
        this.state.options.map(({ display }) => {
          if (
            display.includes(e.target.value) &&
            e.target.value.trim() !== ''
          ) {
            shouldRevert = false;
          }
          return true;
        });

        if (shouldRevert) {
          this.setState({ value: this.props.value });
        }
      }, 500);
    });
  };

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const matches = match(suggestion.display, query);
    const parts = parse(suggestion.display, matches);

    return (
      <MenuItem selected={isHighlighted} component="div">
        {parts.map((part, index) =>
          part.highlight ? (
            <span key={index} style={{ fontWeight: 300 }}>
              {part.text}
            </span>
          ) : part.text.charAt(0) === ' ' ? (
            <strong key={index} style={{ fontWeight: 500 }}>
              &nbsp;{part.text.slice(1)}
            </strong>
          ) : (
            <strong key={index} style={{ fontWeight: 500 }}>
              {part.text}
            </strong>
          )
        )}
      </MenuItem>
    );
  };

  renderSuggestionsContainer = (options) => {
    const { containerProps, children } = options;

    return (
      <Paper {...containerProps} square>
        {children}
      </Paper>
    );
  };

  getSuggestionValue = (suggestion) => suggestion.display;

  renderInput = (inputProps) => {
    const { classes, home, value, ref, ...other } = inputProps;
    return (
      <TextField
        autoFocus={home}
        className={classes.textField}
        value={value}
        fullWidth
        disabled={this.props.disabled}
        InputLabelProps={{ shrink: true }}
        label={this.props.label}
        id={this.props.label}
        variant="outlined"
        inputRef={ref}
        style={{ width: '100%' }}
        InputProps={{
          classes: {
            input: classes.input
          },
          ...other
        }}
      />
    );
  };

  renderCTAInput = () => (
    <Autosuggest
      theme={{
        container: `${this.props.classes.stateContainer} autoSuggestContainer`,
        suggestionsContainerOpen: this.props.classes.optionsContainerOpen,
        suggestionsList: this.props.classes.optionsList,
        suggestion: this.props.classes.suggestion
      }}
      renderInputComponent={this.renderInput}
      suggestions={this.state.filteredOptions}
      onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
      onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
      renderSuggestionsContainer={this.renderSuggestionsContainer}
      getSuggestionValue={this.getSuggestionValue}
      renderSuggestion={this.renderSuggestion}
      inputProps={{
        classes: this.props.classes,
        className: 'autocomplete',
        id: this.props.label,
        placeholder: this.props.placeholder || this.props.label,
        value: this.state.value,
        onChange: (event, props) => {
          const { newValue } = props;

          return this.handleChange(newValue);
        },
        error: this.props.error
      }}
    />
  );

  renderCTAErrorMessage = (conditional = true) =>
    conditional && (
      <Typography style={{ color: 'red' }} variant="body2">
        {this.props.message}
      </Typography>
    );

  renderCTAContainer = (conditional = true) =>
    conditional && (
      <div style={{ display: 'inline-block', width: '90%' }}>
        {this.renderCTAInput()}
        {this.renderCTAErrorMessage()}
      </div>
    );

  renderCTAList = (conditional = true) =>
    conditional && (
      <div
        style={{
          display: 'inline-flex',
          width: '10%',
          alignItems: 'center',
          position: 'relative'
        }}
      >
        <OptionList
          options={this.state.options}
          handleChange={this.handleChange}
          disabled={this.props.disabled}
        />
      </div>
    );

  renderCallToActionFields = () => (
    <div style={{ width: '100%', padding: '8px', display: 'inline-flex' }}>
      {this.renderCTAContainer()}
      {this.renderCTAList()}
    </div>
  );

  renderAutoComplete = () => {
    return this.renderCallToActionFields();
  };

  render() {
    return this.renderAutoComplete();
  }
}

export default withStyles(AutoComplete, styles);

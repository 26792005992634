/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { saveToLocalStorage } from '../../Utilities/saveStateHelper';
import { localStoragePageName } from '../PageContainer';
import { handleChange, updateBreadCrumbs } from '../actions';
import { isMobileDevice } from '../../common/isMobileDevice';

const useStyles = makeStyles()(() => ({
  sectionItem: {
    margin: '2px 0',
    padding: '10px 0',
    paddingLeft: '25px',
    backgroundColor: 'transparent',
    textAlign: 'left',
    border: 'none',
    borderRadius: '4px',
    fontSize: 16,
    cursor: 'pointer',
    textTransform: 'capitalize',
    transition: 'color ease-in-out .25s, background-color ease-in-out .25s',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)'
    },
    '&:focus': {
      outline: 'none'
    }
  },
  noPadding: {
    padding: 0
  },
  button: {
    width: '100%'
  },
  selected: {
    color: '#2c8fff'
  }
}));

const SectionItems = React.memo(({ section }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { breadCrumbs, route, selectedPage, menuOpen } = useSelector(
    (store) => store.pageReducer
  );

  const toggleMenuVisibility = () =>
    dispatch(handleChange('menuOpen', !menuOpen));

  const changePage = async (pageName, displayName) => {
    dispatch(
      updateBreadCrumbs(breadCrumbs, route, selectedPage, displayName, pageName)
    );
    dispatch(handleChange('selectedPage', pageName));
    saveToLocalStorage(`${route}${localStoragePageName}`, pageName);
    isMobileDevice() && toggleMenuVisibility();
    return true;
  };

  const renderItem = (item, pageName) => (
    <button
      key={item.displayName}
      type="button"
      onClick={() => changePage(pageName, item.displayName)}
      disabled={pageName === selectedPage}
      className={`${classes.sectionItem} ${
        pageName === selectedPage && classes.selected
      } ${classes.button}`}
    >
      {item.displayName}
    </button>
  );

  return !section || section.length < 1 ? (
    false
  ) : (
    <div className={classes.sectionItems}>
      {Object.keys(section).map(
        (sectionName) =>
          !section[sectionName].hide &&
          renderItem(section[sectionName], sectionName)
      )}
    </div>
  );
});

SectionItems.propTypes = {
  section: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired
};

export default SectionItems;

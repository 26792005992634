import React, { useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import { TableRowDetail } from '@devexpress/dx-react-grid-material-ui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { makeStyles } from 'tss-react/mui';
import { isMobileDevice } from '../../../common/isMobileDevice';
import CSVButton from '../../../TableContainer/TableUtilities/CSVButton';
import Table from '../../../TableContainer/Table';
import { getColumnWidths } from '../../../TableContainer/TableUtilities/getColumnWidths';
import {
  getColumnCompare,
  numberWithCommas
} from '../../../TableContainer/TableUtilities/columnFormatter';
import {
  renderCurrencyCell,
  renderDefaultCell
} from '../../../TableContainer/TableUtilities/defaultCells';
import { NetworkRequest } from '../../../Utilities/NetworkRequests/NetworkRequests';
import {
  handleGetDataChange,
  handleLastUpdatedChange
} from '../../../lenox/actions';
import { withRouter } from '../../../Utilities/withRouter';
import { logError } from '../../../../Utilities/logError';

const columns = [
  { name: 'buyer', title: 'Buyer', compare: (a, b) => a.localeCompare(b) }
];

const nestColumns = [
  { name: 'date', title: 'Date', compare: (a, b) => new Date(a) - new Date(b) },
  { name: 'revenue', title: 'Revenue', compare: 'priority' },
  { name: 'revenue_returned', title: 'Revenue Returned', compare: 'priority' },
  { name: 'revenue_total', title: 'Revenue Total', compare: 'priority' },
  { name: 'solds', title: 'Solds', compare: 'priority' },
  { name: 'returns', title: 'Returns', compare: 'priority' },
  { name: 'sold_total', title: 'Sold Total', compare: 'priority' }
];

const useStyles = makeStyles()(() => ({
  container: {
    '& tr, & div': {
      position: 'relative',
      zIndex: 0
    }
  }
}));

function flattenData(data) {
  const flattenedData = [];
  data.forEach((row) => {
    row.months.forEach((month) => {
      const flatRow = { ...row, ...month };
      delete flatRow.months;
      flattenedData.push(flatRow);
    });
  });
  return flattenedData;
}

const getClientMonthlyTotals = async (year, endMonth, startMonth, site) => {
  try {
    const { data } = await NetworkRequest(
      'reports',
      {
        year,
        start_month: startMonth,
        end_month: endMonth,
        site_id: site
      },
      'getMonthlyClientTotalsReport'
    );
    return data;
  } catch (error) {
    logError('Error during network request:', error);
    throw error;
  }
};

const ClientMonthlyTotals = (props) => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);

  const requestTime = React.createRef();
  const { classes } = useStyles();

  const getRequestTime = () => requestTime.current.toString();

  useEffect(() => {
    const fetchData = async () => {
      const requestTimeNew = new Date().getTime() / 1000;
      requestTime.current = requestTimeNew;
      setLoading(true);
      try {
        const result = await getClientMonthlyTotals(
          props.year,
          props.endMonth,
          props.startMonth,
          props.site
        );
        if (requestTimeNew.toString() === getRequestTime()) {
          setRows(result.rows);
          const { most_recent } = result;
          props.handleLastUpdatedChange(most_recent);
        }
        setLoading(false);
      } catch {
        setLoading(false);
        setLoadingError(true);
      }
    };
    fetchData();
    props.handleGetDataChange(fetchData);
    // eslint-disable-next-line
  }, [props.year, props.endMonth, props.startMonth, props.site]);

  const renderRowDetail = () => (
    <TableRowDetail contentComponent={(data) => data && rowDetail(data)} />
  );

  const rowDetail = ({ row: { months } }) => {
    return (
      <Paper className={classes.container}>
        <Table
          rows={months}
          columns={nestColumns}
          loading={loading}
          cellComponent={cellComponent}
          defaultColumnWidths={getColumnWidths(nestColumns)}
          getColumnCompare={getColumnCompare(nestColumns)}
          tableColumnLocalStorageName="client_monthly_totals_details_cache"
        />
      </Paper>
    );
  };

  const cellComponent = ({ row, column }) => {
    switch (column.name) {
      case 'revenue':
      case 'revenue_returned':
      case 'revenue_total':
        return renderCurrencyCell(row[column.name]);
      case 'solds':
      case 'returns':
      case 'sold_total':
        return renderDefaultCell(numberWithCommas(row[column.name]));
      default:
        return renderDefaultCell(row[column.name]);
    }
  };

  return (
    <>
      {!isMobileDevice() && (
        <CSVButton
          params={{
            dateRange: true,
            startDate: moment(`${props.year}-${props.startMonth}-01`).format(
              'YYYY-MM-DD'
            ),
            endDate: moment(`${props.year}-${props.endMonth}-01`)
              .endOf('month')
              .format('YYYY-MM-DD')
          }}
          columns={[...columns, ...nestColumns]}
          filename="Client Monthly Totals Report"
          data={rows && flattenData(rows)}
          marginTop="1rem"
          showButton={rows && rows.length > 1 && !loading}
        />
      )}
      <Table
        loading={loading}
        rows={rows}
        columns={columns}
        cellComponent={cellComponent}
        defaultColumnWidths={getColumnWidths(columns)}
        getColumnCompare={getColumnCompare(columns)}
        drawerTitle="Client Monthly Totals"
        tableColumnLocalStorageName="client_monthly_totals_cache"
        loadingError={loadingError}
        renderRowDetail={renderRowDetail}
        pageSize={100}
      />
    </>
  );
};

export default connect(
  (state) => ({
    site: state.filters.site,
    year: state.filters.year,
    endMonth: state.filters.endMonth,
    startMonth: state.filters.startMonth
  }),
  (dispatch) =>
    bindActionCreators(
      {
        handleGetDataChange,
        handleLastUpdatedChange
      },
      dispatch
    )
)(withRouter(ClientMonthlyTotals));

/* eslint-disable import/no-cycle */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, MenuList, MenuItem, IconButton, Button } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import MenuIcon from '@mui/icons-material/Menu';
import { handleChange } from './actions';
import { colors } from '../../Utilities/LenoxColors';
import { isMobileDevice } from '../common/isMobileDevice';
import alphabetizeMenuItems from '../Utilities/AlphabetizeMenuItems';
import { checkAdmin, checkTech } from '../PermissionsWrappers/permissionChecks';
import {
  getFromLocalStorage,
  saveToLocalStorage
} from '../Utilities/saveStateHelper';
import CollapsableNavContainer from './CollapsableNav/CollapsableNavContainer';

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    borderRight: 'solid 1px',
    borderRightColor: colors.lightGray,
    backgroundColor: colors.lenoxLight1,
    boxShadow:
      '0px 1px 5px 0px rgba(0,0,0,0.75), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
    overflow: 'auto'
  },
  item: {
    color: colors.lenoxDark2,
    paddingLeft: '2rem',
    paddingTop: '11px',
    paddingBottom: '11px'
  },
  selected: {
    backgroundColor: `${colors.lenoxDark2} !important`,
    color: colors.lenoxLight1
  },
  itemContainer: {
    outline: 'none !important'
  },
  title: {
    color: colors.lenoxDark2,
    opacity: '.75',
    paddingLeft: '.5rem'
  }
});

const getPageArray = (object) => Object.keys(object);
const localStorageMenuOpen = 'menuOpen';

class SideMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showReportNav: getFromLocalStorage('showReportNav', false)
    };
  }

  toggleButton = () => (
    <Grid item xs={3}>
      <IconButton
        onClick={this.toggleMenuVisibility}
        color="inherit"
        size="large"
      >
        <MenuIcon />
      </IconButton>
    </Grid>
  );

  toggleMenuVisibility = () => {
    this.props.handleChange(localStorageMenuOpen, !this.props.menuOpen);
  };

  renderMenuItems = () =>
    (checkAdmin() || checkTech()) && this.props.collapsable ? (
      <CollapsableNavContainer
        sections={this.props.pageList}
        pageOrder={this.props.pageOrder}
      />
    ) : (
      this.props.sections.map((section) => (
        <div className={this.props.classes.itemContainer} key={section}>
          {section && <p className={this.props.classes.title}>{section}</p>}
          {getPageArray(alphabetizeMenuItems(this.props.pageList)).map(
            (pageName) =>
              this.props.pageList[pageName].displayName !== 'hidden' &&
              (this.props.pageList[pageName].section === section ||
                this.props.pageList[pageName].section.includes(section)) &&
              !this.props.pageList[pageName].hidden && (
                <MenuItem
                  key={pageName}
                  selected={pageName === this.props.selectedPage}
                  classes={{
                    root: this.props.classes.item,
                    selected: this.props.classes.selected
                  }}
                  onClick={() => this.props.changePage(pageName)}
                >
                  {this.props.pageList[pageName].displayName}
                </MenuItem>
              )
          )}
        </div>
      ))
    );

  renderReporting = () => {
    const { showReportNav } = this.state;
    const open = Boolean(showReportNav);
    const toggleDrawer = () => {
      this.setState({ showReportNav: !open });
      saveToLocalStorage('showReportNav', !open);
    };
    return (
      <>
        <Button onClick={toggleDrawer}>
          Show {`${showReportNav ? 'Less' : 'More'}`}
        </Button>
        {this.state.showReportNav && (
          <div className={this.props.classes.itemContainer} key="Reporting">
            <p className={this.props.classes.title}>Reporting</p>
            {getPageArray(alphabetizeMenuItems(this.props.pageList)).map(
              (pageName) =>
                this.props.pageList[pageName].displayName !== 'hidden' &&
                (this.props.pageList[pageName].section === 'Reporting' ||
                  this.props.pageList[pageName].section.includes(
                    'Reporting'
                  )) &&
                !this.props.pageList[pageName].hidden && (
                  <MenuItem
                    key={pageName}
                    selected={pageName === this.props.selectedPage}
                    classes={{
                      root: this.props.classes.item,
                      selected: this.props.classes.selected
                    }}
                    onClick={() => this.props.changePage(pageName)}
                  >
                    {this.props.pageList[pageName].displayName}
                  </MenuItem>
                )
            )}
          </div>
        )}
      </>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid item xs={12} sm={4} lg={2} md={2} className={classes.container}>
        <MenuList>
          {isMobileDevice() && <div>{this.toggleButton()}</div>}
          {this.renderMenuItems()}
          {isMobileDevice() &&
            (checkAdmin() || checkTech()) &&
            this.renderReporting()}
        </MenuList>
      </Grid>
    );
  }
}

export default connect(
  (state) => ({
    menuOpen: state.pageReducer.menuOpen
  }),
  (dispatch) =>
    bindActionCreators(
      {
        handleChange
      },
      dispatch
    )
)(withStyles(SideMenu, styles));

SideMenu.propTypes = {
  pageList: PropTypes.object.isRequired,
  sections: PropTypes.array.isRequired,
  collapsable: PropTypes.bool.isRequired,
  pageOrder: PropTypes.array.isRequired
};

import { defaultInputProps } from '../../../../FormContainer/defaultFormStates';
import { getSellers } from '../../../../common/commonRequests';

export const GROUPING_OPTIONS = [
  { display: 'Source', value: 'source' },
  { display: 'State', value: 'state' },
  { display: 'Credit', value: 'credit' },
  { display: 'Insured', value: 'insured' },
  { display: 'Age', value: 'age' },
  { display: 'Multiple Drivers', value: 'multi_driver' },
  { display: 'Multiple Cars', value: 'multi_car' },
  { display: 'Homeowner', value: 'home_owner' }
];

const siteOptions = [
  { value: 0, display: 'Select a Vertical' },
  { value: '2', display: 'Auto' },
  { value: '4', display: 'Home' },
  { value: '1', display: 'Life' },
  { value: '9', display: 'Final Expense' }
];

const opportunityOptions = [
  {
    value: 'all',
    display: 'All'
  },
  {
    value: 'losing',
    display: 'Losing'
  },
  {
    value: 'potential',
    display: 'Potential'
  }
];

export const filters = () => ({
  site: {
    name: 'site',
    type: 'select',
    label: 'Vertical',
    options: () => siteOptions,
    value: '2',
    inputWidth: 3
  },
  missedBidGrouping: {
    ...defaultInputProps,
    name: 'missedBidGrouping',
    label: 'Select Grouping',
    type: 'multiselect',
    options: () => [{ display: 'All', value: 'all' }, ...GROUPING_OPTIONS],
    value: ['all'],
    allValues: true
  },
  seller: {
    name: 'seller',
    type: 'select',
    label: 'Seller',
    options: (dependency) => getSellers(dependency),
    value: 'all',
    inputWidth: 3,
    dependencyName: 'site'
  },
  opportunity: {
    name: 'opportunity',
    type: 'select',
    label: 'Opportunity',
    options: () => opportunityOptions,
    value: 'all',
    inputWidth: 3
  }
});

/* eslint-disable no-case-declarations */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEqual } from 'lodash';
import { Tooltip, Button, CircularProgress } from '@mui/material';
import { Info } from '@mui/icons-material';
import Table from '../../../TableContainer/Table';
import { getColumnWidths } from '../../../TableContainer/TableUtilities/getColumnWidths';
import { getColumnCompare } from '../../../TableContainer/TableUtilities/columnFormatter';
import { getTableDataWithMostUpdated } from '../../../TableContainer/TableUtilities/buildTableRequest';
import {
  columnTooltip,
  renderCurrencyCell,
  renderDefaultCell,
  renderProfit
} from '../../../TableContainer/TableUtilities/defaultCells';
import Dialog from '../../../Dialog';
import {
  handleGetDataChange,
  handleLastUpdatedChange
} from '../../../lenox/actions';
import { getReportReports } from '../../requests';
import { NetworkRequest } from '../../../Utilities/NetworkRequests/NetworkRequests';
import { isMobileDevice } from '../../../common/isMobileDevice';
import CSVButton from '../../../TableContainer/TableUtilities/CSVButton';
import { withRouter } from '../../../Utilities/withRouter';
import { logError } from '../../../../Utilities/logError';

const columns = [
  { name: 'date', title: 'Date', compare: 'priority' },
  {
    name: 'estimate_revenue',
    csvTitle: 'Estimated Revenue',
    title: columnTooltip(
      'Estimated Revenue',
      'Estimated Revenue counts towards the date a user first visits our site and subtracts returns.'
    ),
    compare: 'priority',
    width: 180
  },
  { name: 'estimate_profit', title: 'Estimated Profit', compare: 'priority' },
  {
    name: 'estimate_profit_percent',
    title: 'Estimated Profit %',
    compare: 'priority'
  },
  {
    name: 'reported_revenue',
    csvTitle: 'Reported Revenue',
    title: columnTooltip(
      'Reported Revenue',
      'Reported Revenue is manually entered revenue.'
    ),
    compare: 'priority',
    width: 180
  },
  { name: 'reported_profit', title: 'Reported Profit', compare: 'priority' },
  {
    name: 'reported_profit_percent',
    title: 'Reported Profit %',
    compare: 'priority'
  },
  { name: 'cost', title: 'Cost', compare: 'priority' },
  { name: 'status', title: 'Status', compare: 'priority', width: 140 }
];

const buildTableParams = (action, props) => ({
  action,
  site_id: props.site
});

class MonthlyTotals extends Component {
  state = {
    rows: [],
    openConfirmDialog: false,
    loading: true,
    loadingError: false
  };

  requestTime = React.createRef();

  getRequestTime = () => this.requestTime.current.toString();

  componentDidMount() {
    this.getData();
    this.props.handleGetDataChange(this.getData);
  }

  componentWillUnmount() {
    this.props.handleLastUpdatedChange('');
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(
        buildTableParams('getMonthlyTotals', this.props),
        buildTableParams('getMonthlyTotals', prevProps)
      )
    ) {
      this.setState({ loading: true, loadingError: false }, this.getData);
      this.props.handleLastUpdatedChange('');
    }
  }

  getData = () => {
    const requestTime = new Date().getTime() / 1000;
    this.requestTime.current = requestTime;
    this.setState({ loading: true, loadingError: false }, () => {
      getTableDataWithMostUpdated(
        buildTableParams('getMonthlyTotals', this.props),
        getReportReports,
        (values) => {
          if (requestTime.toString() === this.getRequestTime()) {
            this.setState(values);
            const { lastUpdated } = values;
            this.props.handleLastUpdatedChange(lastUpdated);
          }
        }
      ).catch((error) => {
        logError(error);
        this.setState({ rows: [], loading: false, loadingError: true });
      });
    });
  };

  updateRow = (row, name, value) => {
    const { rows } = this.state;
    rows.forEach(({ date }, index) => {
      if (row.date === date) {
        rows[index][name] = value;
      }
    });
    this.setState({ rows });
  };

  changeStatus = async (newStatus, row) => {
    this.updateRow(row, 'processing', true);
    const { data } = await NetworkRequest(
      'closingMonth',
      {
        year: this.state.currentRow.year,
        month: this.state.currentRow.month,
        status: newStatus
      },
      'update'
    );
    this.updateRow(row, 'processing', false);
    if (data && data.success) {
      this.updateRow(row, 'status', newStatus);
    } else {
      this.setState({
        errorDialog:
          data && !data.success && data.message
            ? data.message
            : 'Something went wrong. Please try again.'
      });
    }
  };

  openConfirmationDialog = (row) =>
    this.setState({ openConfirmDialog: true, currentRow: { ...row } });

  renderConfirmDialog = () => (
    <Dialog
      title="Confirm"
      children={
        +this.state.currentRow.status === 0
          ? `Are you sure you wish to close ${this.state.currentRow.date} and prevent further returns and value adjustments for all verticals?`
          : `Are you sure you wish to re-open ${this.state.currentRow.date}?`
      }
      open={this.state.openConfirmDialog}
      onRequestSave={() => {
        this.setState({ openConfirmDialog: false });
        this.changeStatus(
          this.statusButton()[+this.state.currentRow.status].newStatus,
          this.state.currentRow || {}
        );
      }}
      onRequestClose={() =>
        setTimeout(() => this.setState({ openConfirmDialog: false }), 300)
      }
      type="Confirm"
      saveButtonText="Submit"
    />
  );

  statusButton = () => ({
    0: {
      color: 'green',
      tooltip: 'Click to Close Month',
      text: 'Open',
      newStatus: 1
    },
    1: {
      color: 'red',
      tooltip: 'Click to Open Month',
      text: 'Closed',
      newStatus: 0
    }
  });

  cellComponent = ({ row, column }) => {
    switch (column.name) {
      case 'estimate_revenue':
      case 'reported_revenue':
      case 'cost':
        return row[column.name] !== 'NA' && row[column.name] !== '0.000'
          ? renderCurrencyCell(row[column.name])
          : renderDefaultCell('NA');
      case 'estimate_profit':
      case 'reported_profit':
        return renderProfit(row[column.name], '$');
      case 'estimate_profit_percent':
      case 'reported_profit_percent':
        return renderProfit(row[column.name], '', '%');
      case 'status':
        const statusData = this.statusButton()[+row.status];
        return renderDefaultCell(
          <Tooltip title={statusData.tooltip} placement="top">
            <Button
              variant="contained"
              style={{ color: 'white', background: statusData.color }}
              onClick={() => this.openConfirmationDialog(row)}
              disabled={row.processing}
            >
              {row.processing ? (
                <CircularProgress color="inherit" size={25} />
              ) : (
                statusData.text
              )}
            </Button>
          </Tooltip>
        );
      default:
        return renderDefaultCell(row[column.name]);
    }
  };

  render() {
    const { rows, loading, loadingError, currentRow } = this.state;

    return (
      <>
        <div
          style={{
            padding: '.3rem 1rem',
            margin: '.5rem auto',
            position: 'relative'
          }}
        >
          <Info />
          <div style={{ position: 'absolute', top: '0.6em', left: '3em' }}>
            Note: All revenue totals will not include external offer revenue.
          </div>
        </div>
        {!isMobileDevice() && (
          <CSVButton
            params={this.props}
            columns={columns}
            filename="Monthly Totals Report"
            data={rows}
            marginTop="1rem"
            showButton={Boolean(rows.length) && !loading}
          />
        )}
        <Table
          loading={loading}
          rows={rows}
          columns={columns}
          cellComponent={this.cellComponent}
          defaultColumnWidths={getColumnWidths(columns)}
          getColumnCompare={getColumnCompare(columns)}
          drawerTitle="Monthly Totals"
          tableColumnLocalStorageName="monthly_totals_cache"
          loadingError={loadingError}
        />
        {currentRow && this.renderConfirmDialog()}
      </>
    );
  }
}

export default connect(
  (state) => ({
    site: state.filters.site,
    breadCrumbs: state.pageReducer.breadCrumbs
  }),
  (dispatch) =>
    bindActionCreators(
      {
        handleGetDataChange,
        handleLastUpdatedChange
      },
      dispatch
    )
)(withRouter(MonthlyTotals));

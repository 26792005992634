/* eslint-disable react/no-unused-state */
import React from 'react';
import { Navigate, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tabs, Tab, Grid, AppBar, Drawer } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import { withStyles } from 'tss-react/mui';
import {
  checkAgent,
  checkAffiliate,
  checkSuperAffiliate,
  checkAdmin,
  checkAccounting,
  checkAffiliateManager,
  checkTech,
  checkPowerUser,
  checkAmb
} from '../PermissionsWrappers/permissionChecks';
import Logo from '../../assets/images/otto_ai_logo.png';
import { colors } from '../../Utilities/LenoxColors';
import { LastUpdated } from '../common/commonComponents';
import { handleUpdateDataCallChange } from '../lenox/actions';
import { isMobileDevice } from '../common/isMobileDevice';
import { withRouter } from '../Utilities/withRouter';
import { config } from '../../config';

const styles = () => ({
  indicator: {
    backgroundColor: `${colors.lenoxLight1}!important`
  },
  mobileDrawer: {
    top: '88px',
    width: '200px',
    alignItems: 'flex-end'
  },
  mobileNavIcon: {
    color: '#ffffff',
    position: 'absolute',
    right: 0,
    top: 10
  },
  mobileNavItem: {
    width: '100%',
    textAlign: 'right',
    alignItems: 'flex-end',
    borderBottom: '1px solid rgba(48, 56, 65, .1)',
    padding: '0',
    justifyContent: 'flex-end',
    minHeight: 'auto'
  },
  mobileNavLink: {
    width: '100%',
    padding: '10px 15px',
    color: 'rgb(48, 56, 65)',
    textDecoration: 'none',
    textTransform: 'uppercase',
    '&.active': {
      color: '#2479c1',
      fontWeight: 700,
      backgroundColor: 'rgba(48, 56, 65, .1)'
    }
  }
});

export const ROUTE_NAMES = {
  Dashboard: 'Dashboard',
  Reporting: 'Reporting',
  Setup: 'Setup',
  Status: 'Status',
  Agents: 'Agents',
  Accounting: 'Accounting',
  Settings: 'Settings'
};

let routeMapping = {
  0: <Navigate to="/Dashboard" replace />,
  1: <Navigate to="/Reporting" replace />,
  2: <Navigate to="/Setup" replace />,
  3: <Navigate to="/Status" replace />,
  4: <Navigate to="/Agents" replace />,
  5: <Navigate to="/Accounting" replace />,
  6: <Navigate to="/Settings" replace />,
  7: <Navigate to="/Logout" replace />
};

let urlMapping = {
  Dashboard: 0,
  Reporting: 1,
  Setup: 2,
  Status: 3,
  Agents: 4,
  Accounting: 5,
  Settings: 6,
  Logout: 7
};

const updateMapping = (removeIndex = false) => {
  if (removeIndex !== false) {
    let newIndex = 0;
    const newURLMapping = {};
    const newRouteMapping = {};

    Object.keys(urlMapping).map((key) => {
      if (urlMapping[key] !== removeIndex) {
        newURLMapping[key] = newIndex;
        newRouteMapping[newIndex] = routeMapping[urlMapping[key]];
        newIndex += 1;
      }
      return true;
    });

    urlMapping = newURLMapping;
    routeMapping = newRouteMapping;
  }
};

const setAffiliateMapping = () => {
  if (!checkAffiliate()) return;
  updateMapping(5);
  updateMapping(4);
  updateMapping(3);
};

const setAdminMapping = () => {
  if (!checkAdmin()) return;
  updateMapping(5);
};

const setAgentMapping = () => {
  if (!checkAgent()) return;
  updateMapping(5);
  updateMapping(3);
  updateMapping(2);
  updateMapping(1);
  updateMapping(0);
};

const setTechMapping = () => {
  if (!checkTech()) return;
  updateMapping(5);
};

const setSuperMapping = () => {
  if (!checkSuperAffiliate()) return;
  updateMapping(5);
  updateMapping(4);
  updateMapping(3);
};

const setAmbMapping = () => {
  if (!checkAmb()) return;
  updateMapping(5);
  updateMapping(4);
  updateMapping(3);
};

const setAffiliateManager = () => {
  if (!checkAffiliateManager()) return;
  updateMapping(5);
  updateMapping(4);
  updateMapping(3);
};

const setPowerUser = () => {
  if (!checkPowerUser()) return;
  updateMapping(5);
  updateMapping(4);
  updateMapping(3);
};

const setAccountingMapping = () => {
  if (!checkAccounting()) return;
  updateMapping(4);
  updateMapping(3);
  updateMapping(2);
  updateMapping(1);
  updateMapping(0);
};

const setTabName = (tabName) => {
  if (tabName === 'Affiliates' || tabName === 'Agents') {
    if (
      checkAffiliate() ||
      checkSuperAffiliate() ||
      checkAmb() ||
      checkAgent() ||
      checkAffiliateManager() ||
      checkPowerUser()
    ) {
      return 'Dashboard';
    }
  }
  if (tabName === 'Accounting' && checkAccounting()) {
    return 'Reporting';
  }
  return tabName;
};

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      topTabIndex: 0,
      fireRedirect: 0,
      loaded: false,
      toggleMobileNav: false
    };
  }

  UNSAFE_componentWillMount() {
    this.setUrlMappings();
    this.setUrlTab();
  }

  setUrlMappings = () => {
    setAffiliateMapping();
    setAdminMapping();
    setAgentMapping();
    setSuperMapping();
    setAmbMapping();
    setAccountingMapping();
    setTechMapping();
    setAffiliateManager();
    setPowerUser();
    this.setState({ loaded: true });
  };

  setUrlTab = () => {
    const locationURL = window.location.pathname.replace(
      `${config.routeBaseName}`,
      ''
    );

    const locationPage = locationURL.split('/')[1];

    if (typeof urlMapping[locationPage] !== 'undefined') {
      this.setState({ topTabIndex: urlMapping[locationPage] });
    } else {
      this.changeTabIndex(0);
    }
  };

  handleChange = (event, index) => {
    this.setState({ fireRedirect: 1 }, () => this.changeTabIndex(index));
  };

  changeTabIndex = (index) => {
    this.setState(
      {
        topTabIndex: index || 0
      },
      () => this.setState({ fireRedirect: 1 })
    );
  };

  renderTab = (tabName) => (
    <Tab
      key={tabName}
      style={{ margin: '0 auto', minWidth: 'auto', color: colors.lenoxLight1 }}
      label={setTabName(tabName)}
    />
  );

  redirectHandler = () => {
    const { fireRedirect, topTabIndex } = this.state;
    if (fireRedirect === 1) {
      this.setState({ fireRedirect: 0 });
      return routeMapping[topTabIndex];
    }
  };

  componentDidUpdate(prevProps) {
    this.toggleMobileMenuOverflow();

    const { location: prevLocation } = prevProps.router ?? {};
    const { location: currentLocation } = this.props.router ?? {};

    if (prevLocation.pathname === currentLocation.pathname) {
      return;
    }

    this.setUrlTab();
  }

  renderMobileNav() {
    const open = !!this.state.toggleMobileNav;
    const { classes } = this.props;

    const toggleDrawer = () => {
      setTimeout(() => {
        window.scroll({ top: -1, left: 0, behavior: 'smooth' });
      }, 10);

      this.setState((state) => ({ toggleMobileNav: !state.toggleMobileNav }));
    };

    const handleClose = () => {
      this.setState({ toggleMobileNav: false });
    };

    return (
      <>
        <IconButton
          color="inherit"
          onClick={toggleDrawer}
          className={classes.mobileNavIcon}
          size="large"
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          open={open}
          onClose={handleClose}
          anchor="right"
          classes={{
            paper: classes.mobileDrawer
          }}
        >
          {Object.keys(urlMapping).map((key) => (
            <MenuItem
              key={key}
              onClick={handleClose}
              className={classes.mobileNavItem}
            >
              <NavLink
                to={key === 'Dashboard' ? '/' : `/${setTabName(key)}`}
                className={classes.mobileNavLink}
              >
                {setTabName(key)}
              </NavLink>
            </MenuItem>
          ))}
        </Drawer>
      </>
    );
  }

  renderTabs = () => (
    <Tabs
      classes={{
        indicator: this.props.classes.indicator
      }}
      value={this.state.topTabIndex}
      onChange={this.handleChange}
      variant="scrollable"
      scrollButtons={false}
      indicatorColor="primary"
      textColor="inherit"
    >
      {Object.keys(urlMapping).map((key) => this.renderTab(key))}
      {this.props.lastUpdated && (
        <LastUpdated
          updatedAt={this.props.lastUpdated}
          onReload={() => {
            this.props.handleUpdateDataCallChange();
            this.props.getTableData();
          }}
          lastUpdated={this.props.lastUpdated}
          currentPage={this.props.selectedPage}
        />
      )}
    </Tabs>
  );

  renderLogo = () => (
    <Grid
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '125px',
        cursor: 'pointer'
      }}
      item
      onClick={() => {
        this.changeTabIndex(0);
      }}
    >
      <img
        src={Logo}
        alt=""
        width={125}
        aria-label='Navigate to "Dashboard" page'
      />
    </Grid>
  );

  toggleMobileMenuOverflow = () => {
    if (this.state.toggleMobileNav) {
      document.documentElement.classList.add('overflow-hidden');

      return;
    }

    document.documentElement.classList.remove('overflow-hidden');
  };

  renderAppBar = () => (
    <AppBar
      style={{
        backgroundColor: colors.lenoxDark1,
        padding: isMobileDevice() ? '10px 0' : 'auto',
        minHeight: isMobileDevice() ? '55px' : 'auto'
      }}
      position="static"
      color="default"
    >
      <Grid
        container
        justifyContent="left"
        alignItems="center"
        style={{ paddingLeft: '.5rem' }}
      >
        {this.renderLogo()}
        <Grid item sx={{ width: 'calc(100% - 125px)' }}>
          {isMobileDevice() ? this.renderMobileNav() : this.renderTabs()}
        </Grid>
      </Grid>
    </AppBar>
  );

  render() {
    if (!this.state.loaded) return null;

    return (
      <Grid id="navigation" container justifyContent="center">
        <Grid item xs={12}>
          {this.renderAppBar()}
        </Grid>
        {this.redirectHandler()}
      </Grid>
    );
  }
}

export default connect(
  (state) => ({
    route: state.pageReducer.route,
    lastUpdated: state.lenox.lastUpdated,
    getTableData: state.lenox.getTableData,
    selectedPage: state.pageReducer.selectedPage
  }),
  (dispatch) =>
    bindActionCreators(
      {
        handleUpdateDataCallChange
      },
      dispatch
    )
)(withStyles(withRouter(Navigation), styles));

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import { connect } from 'react-redux';
import { IconButton, Snackbar, Tooltip } from '@mui/material';
import { Link } from '@mui/icons-material';
import { isMobileDevice } from '../common/isMobileDevice';

class CreatePageLink extends React.Component {
  state = {
    snackBarOpen: false
  };

  copyToClipBoard = () => {
    const textField = document.createElement('textarea');
    const url = window.location.origin;
    textField.innerText = `${url}/${this.props.route}&page=${this.props.selectedPage}`;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    this.toggleSnackBar();
  };

  toggleSnackBar = () =>
    this.setState({ snackBarOpen: !this.state.snackBarOpen });

  renderSnackBar = () => (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={this.state.snackBarOpen}
      onClose={() => this.toggleSnackBar()}
      autoHideDuration={3000}
      ContentProps={{
        'aria-describedby': 'message-id'
      }}
      message={<span>Link Copied!</span>}
    />
  );

  render() {
    return (
      <div
        style={{
          display: isMobileDevice() ? 'none' : 'inline-block',
          marginRight: isMobileDevice() ? '0' : '1rem'
        }}
      >
        <Tooltip title="Click to copy a link to this report" placement="bottom">
          <IconButton
            onClick={this.copyToClipBoard}
            color="inherit"
            style={{ padding: isMobileDevice() ? '0' : '12px' }}
            size="large"
          >
            <Link />
          </IconButton>
        </Tooltip>
        {this.renderSnackBar()}
      </div>
    );
  }
}

export default connect((state) => ({
  selectedPage: state.pageReducer.selectedPage,
  route: state.pageReducer.route
}))(CreatePageLink);

/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/jsx-no-duplicate-props */
import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Grid,
  Typography,
  TextField,
  Box,
  InputAdornment
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { colors } from '../../../Utilities/LenoxColors';

const styles = () => ({
  formControl: {
    marginTop: '.5rem',
    marginBottom: '.5rem',
    position: 'relative'
  },
  margin: {
    marginRight: '1rem'
  },
  checkbox: {
    color: colors.lenoxDark2
  },
  colorPrimary: {
    color: `${colors.lenoxDark2}!important`
  },
  inline: {
    display: 'inline !important'
  },
  centered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  slider: {
    '& .MuiSlider-rail': {
      background: 'linear-gradient(to left, red 91%, green 9%)',
      opacity: 1
    },
    '& .MuiSlider-thumb': {
      border: ' 1px solid rgb(110 110 110)',
      boxShadow: 'none'
    }
  },
  errorMessage: {
    position: 'absolute',
    right: '0px',
    color: 'red',
    fontSize: '12px'
  }
});

const Scale = (props) => {
  const [value, setValue] = useState('0');
  const sliderRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const numberPattern = /^-?\d*$/;

    if (inputValue !== '' && !numberPattern.test(inputValue)) {
      return;
    }

    setValue(inputValue);
    const numberValue = Number(inputValue);
    if (numberValue && numberValue !== Number(value))
      props.onChange(props.name, numberValue);
  };

  const handleBlur = () => {
    const numberValue = Number(value) || 0;
    props.onChange(props.name, numberValue);
  };

  const valueLabelFormat = (value) => {
    const redIntensity = Math.max(30, -value);
    const greenIntensity = Math.max(300, value);
    const red = `rgba(255, 0, 0, ${redIntensity / 100})`;
    const green = `rgba(0, 255, 0, ${greenIntensity / 1000})`;

    return { red, green };
  };

  useEffect(() => {
    const numberValue = Number(value);
    if (numberValue < -100 || numberValue > 1000) {
      setErrorMessage('Number must be between -100 and 1000.');
    } else {
      setErrorMessage('');
    }
  }, [value]);

  useEffect(() => {
    setValue(Math.round(props.value));
  }, [props.value]);

  useEffect(() => {
    if (sliderRef.current) {
      const { red, green } = valueLabelFormat(value);

      const parsedValue = Number(value) || 0;
      if (!value || parsedValue === 0) {
        sliderRef.current.querySelector(
          '.MuiSlider-rail'
        ).style.background = `linear-gradient(to left, green 91%, red 9%)`;
        sliderRef.current.querySelector('.MuiSlider-rail').style.opacity =
          '0.38';
        sliderRef.current.querySelector('.MuiSlider-thumb').style.color =
          'transparent';
      } else {
        sliderRef.current.querySelector(
          '.MuiSlider-rail'
        ).style.background = `linear-gradient(to left, ${green} 91%, ${red} 9%)`;
        sliderRef.current.querySelector('.MuiSlider-rail').style.opacity = '1';
        sliderRef.current.querySelector('.MuiSlider-thumb').style.color =
          value > 0 ? `${green}` : `${red}`;
      }
    }
  }, [value]);

  const renderScale = (classes) => {
    const { adornmentPosition, adornment } = props;
    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Box
          style={{
            width: props?.subLabel ? '100%' : '85%'
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            <Typography
              id="input-slider"
              gutterBottom
              style={{ fontWeight: 'bold', maxWidth: '80%' }}
            >
              {props.label}
              {props.subLabel && (
                <Typography variant="caption" style={{ marginLeft: '15px' }}>
                  {props.subLabel}
                </Typography>
              )}
            </Typography>
            <Grid
              container
              spacing={2}
              alignItems="center"
              style={{ width: '80px' }}
            >
              <Grid item>
                <TextField
                  variant="standard"
                  value={value}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  inputProps={{
                    // ...props.
                    type: 'text',
                    'aria-labelledby': 'input-slider'
                  }}
                  style={{ width: '55px' }}
                  InputProps={{
                    startAdornment:
                      adornmentPosition === 'start' ? (
                        <InputAdornment position={adornmentPosition}>
                          {adornment || ''}
                        </InputAdornment>
                      ) : null,
                    endAdornment:
                      adornmentPosition === 'end' ? (
                        <InputAdornment
                          position={adornmentPosition}
                          style={{ marginLeft: '0px' }}
                        >
                          {adornment || ''}
                        </InputAdornment>
                      ) : null
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          {props.subSubLabel && (
            <Typography variant="caption" style={{ marginLeft: '0' }}>
              {props.subSubLabel}
            </Typography>
          )}
          {/* Conditionally render error message */}
          {errorMessage && (
            <Typography className={classes.errorMessage}>
              {errorMessage}
            </Typography>
          )}
        </Box>
      </Box>
    );
  };

  const { classes, name, centered } = props;
  return (
    <Grid key={name} container justifyContent="center" alignItems="center">
      <Grid
        item
        xs={12}
        className={classNames(
          classes.margin,
          classes.formControl,
          centered ? classes.centered : ''
        )}
      >
        {renderScale(classes)}
      </Grid>
    </Grid>
  );
};

Scale.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

Scale.defaultProps = {
  label: '',
  error: false
};

export default withStyles(Scale, styles);
